import { PropsWithChildren, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  globalStyles,
  interaction___search,
} from '@neui/styleguide-commerzbank';

import { useRuntimeSettings } from '@utils/config';
import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { SeoData } from '@components/SEO/SeoData';
import { MobileChecker } from '@utils/MobileChecker';
import { Header } from '@components/neui-components/atoms/Header';
import { LanguageSwitch } from '@components/LanguageSwitch';
import { useTranslation } from '@utils/i18n';
import { Footer } from '@components/neui-components/atoms/Footer';
import { baseTheme } from 'styling/stitches.config';
import { useMakeLink } from '@components/Link';
import { CdsSearchAndResults } from '@components/Search/CdsSearchAndResults';
import { GA4TrackPageView } from '@utils/tracking';
import { maskIfNecessary, stripQueryParams } from '@components/Search/helpers';

import { NavLink } from '../NavItems/CdsNavItems';
import { OnlineBankingIcon, useSearchRef } from './Layout.helpers';
import {
  GrowingMain,
  IconLinkWrapper,
  MinHeightLayout,
  NavWrapper,
} from './Layout.styles';

import type { LayoutProps } from './types';

export default function Layout({
  children,
  title,
  translateTitle,
  pageType,
  slug,
  excerpt,
  pageTemplate,
}: PropsWithChildren<LayoutProps>) {
  globalStyles();
  globalStyles();

  const {
    search: { apiUrl: searchUrl, topSearchTerms },
    staticContent: { mostReadArticles, googleSiteVerificationTag },
    tracking: {
      ga4: { enabled: enableGA4Tracking, eventContext },
    },
  } = useRuntimeSettings();
  const { $t } = useTranslation();

  const isSearchPage = pageType === 'Search Page';
  const isIndexPage = pageType === 'Homepage';

  const { trackPageView, enableLinkClickTracking } = useTracker(Layout.name);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const makeLink = useMakeLink();
  const inputRef = useRef<HTMLInputElement>(null);
  const previousPageRef = useRef<string | null>(null);

  const { openSearch, setOpenSearch, setCloseSearch } =
    useSearchRef(isSearchPage);

  const documentTitle = translateTitle ? $t(title as any) : title;

  useEffect(() => {
    const portalContext = createPortalEntity($t('HOME_GPP'), documentTitle);
    enableLinkClickTracking?.({
      pseudoClicks: true,
      options: {
        denylist: isIndexPage ? ['c-fUCAFi'] : [],
      },
      context: [portalContext],
    });

    // hack: since snowplow tracking sometimes seems to pick up the old title, postpone tracking the page view into the next animation frame
    window.requestAnimationFrame(() => {
      const pageUrl = maskIfNecessary(window.location.href);

      const normalizedPageUrl = stripQueryParams(pageUrl);
      const normalizedPreviousUrl = stripQueryParams(
        previousPageRef.current || '',
      );
      // Track 'Result Page' only if it's the first page load, not during navigation.
      const isFirstPageView = !previousPageRef.current;
      if (isFirstPageView || normalizedPageUrl !== normalizedPreviousUrl) {
        const isResultPage = /\?q=/.test(pageUrl);
        const GA4PageType = isResultPage ? 'Result Page' : pageType;
        const GA4PageTemplate = isResultPage ? undefined : pageTemplate;
        enableGA4Tracking &&
          GA4TrackPageView(
            eventContext,
            pageUrl,
            seoTitle,
            GA4PageType,
            GA4PageTemplate,
            previousPageRef.current || document.referrer,
          );
        previousPageRef.current = pageUrl;
        trackPageView?.({ context: [portalContext] });
      }
    });
  }, [enableLinkClickTracking, trackPageView, documentTitle, isIndexPage, $t]);

  const SearchButton = (
    <NavLink
      label={$t('SEARCH')}
      icon={interaction___search}
      onClick={() => openSearch.current()}
    />
  );

  const OnlineBankingButton = (
    <NavLink
      label={$t('ONLINE_BANKING')}
      icon={<OnlineBankingIcon />}
      href={$t('ONLINE_BANKING_URL')}
      isSvg={true}
    />
  );

  const navItems: JSX.Element[] = [
    SearchButton,
    <LanguageSwitch key="navitem-2" />,
    OnlineBankingButton,
  ];

  const isSearchVisible = isIndexPage || isSearchPage;
  const metaRobotsTag = isSearchPage ? 'noindex, nofollow' : 'noindex, follow';

  const mobileChecker = new MobileChecker();
  const mobileOs = mobileChecker.deviceType;

  const is404 = slug.includes('404');
  const seoTitle = isIndexPage
    ? is404
      ? documentTitle
      : $t('SEO_TITLE_APPENDIX_GPP')
    : documentTitle + ' - ' + $t('SEO_TITLE_APPENDIX_GPP');

  return (
    <MinHeightLayout className={baseTheme}>
      <SeoData
        title={seoTitle}
        description={excerpt ?? undefined}
        absoluteUrl={makeLink({ href: slug, absoluteUrl: true }) ?? ''}
        slug={slug}
        metaRobotsTag={metaRobotsTag}
        googleSiteVerificationTag={googleSiteVerificationTag}
        isSearchVisible={isSearchVisible}
        isIndexPage={isIndexPage || isSearchPage}
        imageSrc={makeLink({
          href: '/commerzbank-logo.png',
          alwaysPrependBasePath: true,
          absoluteUrl: true,
        })}
      />

      <Header
        title={$t('HEADER_TITLE_GPP')}
        navItems={
          <nav>
            <NavWrapper>
              {navItems.map((item, idx) => (
                <IconLinkWrapper key={idx}>{item}</IconLinkWrapper>
              ))}
            </NavWrapper>
          </nav>
        }
      />
      <CdsSearchAndResults
        isSearchPage={isSearchPage}
        searchApiUrl={searchUrl}
        mostSearchedTerms={topSearchTerms}
        pageType={pageType}
        inputRef={inputRef}
        mobileOs={mobileOs}
        mostReadArticles={mostReadArticles}
        setOpenSearch={setOpenSearch}
        setCloseSearch={setCloseSearch}
        onHasResults={setHasSearchResults}
      />
      <GrowingMain>{!hasSearchResults && children}</GrowingMain>
      <Footer />
    </MinHeightLayout>
  );
}
